// ** Icon import
import HomeOutline from 'mdi-material-ui/HomeOutline'
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import BoyIcon from '@mui/icons-material/Boy';
import PaymentsIcon from '@mui/icons-material/Payments';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import SettingsIcon from '@mui/icons-material/Settings';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
const navigation = () => {
  return [
    {
      icon: HomeOutline,
      title: 'Dashboard',
      path: '/dashboards'
    },
    {
      sectionTitle: 'Apps & Pages'
    },

    {
      icon: BoyIcon,
      title: 'Client ',
      path: '/client '
    },
    {
      icon: WheelchairPickupIcon,
      title: 'Caregiver',
      path: '/caregiver'
    },
    {
      icon: HolidayVillageIcon,
      title: 'Holiday',
      path: '/holiday'
    },
    {
      icon: BookmarkAddedIcon,
      title: 'Total Appointment',
      path: '/appointment'
    },
    {
      title: 'Settings',
      icon: SettingsIcon,
      children: [
        {
          //icon: PaymentsIcon,
          title: 'Service Cost',
          path: '/settings'
        },
        {
          title: 'Content Management',
          path: '/content'
        },
        {
          //icon:PaymentsIcon,
          title: 'Add Languages',
          path: '/languages'
        },
        {
          title: 'Add Services',
          path: '/services'
        },
        {
          title: 'Add Special Needs',
          path: '/special_needs'
        }

      ]
    },
    {
      title: 'Payment',
      icon: PaymentsIcon,
      children: [
        {
          //icon: PaymentsIcon,
          title: `Today's Payment`,
          path: '/todays-payment'
        },
        {
          //icon:PaymentsIcon,
          title: 'Payment History',
          path: '/payment-history'

        },
      ]
    },
    {
      icon: HolidayVillageIcon,
      title: 'APK/API Sharing',
      path: '/apk-api-sharing'
    },
  ]
}

export default navigation;
